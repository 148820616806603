import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import api from '../../Authenticaltion/api';
import './DetailsSalarySlip.css';
// Optional: for custom styles
import html2canvas from 'html2canvas';
import { toast } from 'react-toastify';

const ViewMySalarySlips = () => {
  const { Payslips_id } = useParams();
  const [salaryData, setSalaryData] = useState(null);

  useEffect(() => {
    if (Payslips_id) {
      fetchSalaryData(Payslips_id);
    }
  }, [Payslips_id]);
  const monthMapping = {
    "January": 0,
    "February": 1,
    "March": 2,
    "April": 3,
    "May": 4,
    "June": 5,
    "July": 6,
    "August": 7,
    "September": 8,
    "October": 9,
    "November": 10,
    "December": 11,
  };

  const getLopDays = (salary_month, salary_year, day_worked) => {
    const monthNumber = monthMapping[salary_month];
    const totalDays = new Date(salary_year, monthNumber + 1, 0).getDate(); // Get the total days of the month
    return totalDays - day_worked; // Calculate LOP Days
  };

  const fetchSalaryData = async (id) => {
    try {
      const response = await api.get(`/show_user_payslips_indetails/${id}`);
      setSalaryData(response.data);
    } catch (error) {
      console.error('Error fetching salary data:', error);
    }
  };

  // Ensure all necessary data is available before rendering
  if (!salaryData) {
    return <div>Loading...</div>;
  }

  // Parse earnings and deductions JSON
  const earnings = salaryData.earnings ? JSON.parse(salaryData.earnings) : [];
  const deductions = salaryData.deductions ? JSON.parse(salaryData.deductions) : [];

  // Function to handle PDF download

  const handleDownload = async () => {
    try {
      // Get the HTML content of the salary slip
      const salarySlipContent = document.getElementById('salary-slip').innerHTML;

      // Create a new window
      const printWindow = window.open('', '', 'height=600,width=800');

      // Write HTML content to the new window
      printWindow.document.open();
      printWindow.document.write(`
        <html>
        <head>
          <title>Print Salary Slip</title>
          <style>
            /* Add any styles you want to include for the print view */
            body { font-family: Arial, sans-serif;  }
            .salary-slip-container {
  padding: 20px;
  background-color: #f5f5f5;
  font-family: 'Helvetica', Arial, sans-serif;
  line-height: 1.6;
}

.salary-slip-buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.salary-slip-buttons button {
  margin-left: 10px;
  padding: 8px 15px;
  font-size: 0.95rem;
  font-weight: bold;
  border-radius: 5px;
}

.salary-slip-buttons .btn-success {
  background-color: #28a745;
  border: none;
  color: white;
}

.salary-slip-buttons .btn-danger {
  background-color: #dc3545;
  border: none;
  color: white;
}

.salary-slip {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid #bbb6b6;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.company-info h1 {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.company-info p {
  font-size: 0.9rem;
  line-height: 1.4;
  color: #777;
}

.company-logo img {
  height: 60px;
  width: auto;
}

.employee-pay-summary {
  margin-bottom: 30px;
}

.summary-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #555;
}

/* Layout for 3 columns */
.pay-summary-columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.employee-column {
  width: 30%;
}
  .summary {
  display: flex;
  justify-content: space-between; /* Distributes space between the two divs */
  align-items: center; /* Vertically aligns the items */
}

.summary-left {
  flex: 1; /* This will take up available space on the left */
}

.summary-right {
  text-align: right; /* Ensures text in this div is right-aligned */
}


.summary-table {
  width: 100%;
  border-collapse: collapse;
}

.summary-table td {
  padding: 8px;
  border: 1px solid #ddd;
  font-size: 0.95rem;
}

.earnings-deductions {
  margin: 40px auto;
}

.table {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
}

.table th {
  background-color: #f8f9fa;
  text-align: left;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
}

.table td {
  padding: 8px;
  border: 1px solid #ddd;
  font-size: 0.95rem;
}

tfoot {
  font-weight: bold;
  background-color: #e9ecef;
}

.text-center {
  text-align: center;
}

.footer {
  text-align: center;
  color: #777;
  margin-top: 20px;
  padding-top: 15px;
}

.footer p {
  font-size: 0.9rem;
  font-style: italic;
}

/* Net Payable section */
.net-payable {
  padding-top: 20px;
  border: 3px dotted #bbb6b6;
  text-align: center;
  margin: 20px 0;
  padding: 15px;
  background-color: #f1f1f1;
  border-radius: 8px;
}

.net-payable p {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.net-payable p.italic {
  font-size: 0.95rem;
  font-style: italic;
  color: #555;
}

  
            /* You can add other print-specific styles here */
          </style>
        </head>
        <body>
          ${salarySlipContent}
        </body>
        </html>
      `);
      printWindow.document.close();

      // Wait for the document to finish loading
      printWindow.onload = () => {
        printWindow.print();
      };
    } catch (error) {
      console.error('Error printing salary slip:', error);
      toast.error('Failed to print salary slip. Please try again later.');
    }
  };


  return (
    <div className="salary-slip-container">
      <div className="salary-slip-buttons">
        <button className="btn btn-sm btn-success" onClick={handleDownload}>  <i className="fas fa-print"></i></button>
      </div>
      <div className="salary-slip" id="salary-slip">
        <div className="header">
          <div className="company-info">
            <h3><strong>Ajatus Software</strong></h3>
            <p>South Block, Fourth Floor, New OCAC Towers, Acharya Vihar, Bhubaneswar, Odisha 751013</p>
          </div>
          <div className="company-logo">
            <img src="/images/Ajatus_logo.png" alt="Company Logo" />
          </div>
        </div>

        <div className="summary">
          <div className="summary-left">
            <p><strong>Payslip for the month of {salaryData.salary_month}, {salaryData.salary_year}</strong></p>
          </div>
          <div className="summary-right">
            <p>Payslip Reference:SLIP/{salaryData.payslip_id}</p>
          </div>
        </div>

        <div className="employee-pay-summary">
          <p className="summary-header"><strong>EMPLOYEE PAY SUMMARY</strong></p>
          <div className="pay-summary-columns">
            {/* First Column */}
            <div className="employee-column">
              <p>Name: {salaryData.employee_name || 'Not Available'}</p>
              <p>Designation: {salaryData.designation || 'Not Available'}</p>
              <p>Bank Account No: {salaryData.account_number || 'Not Available'}</p>
            </div>

            {/* Second Column */}
            <div className="employee-column">
              <p>Employee No: {salaryData.employee_id || 'Not Available'}</p>
              <p>UAN: {salaryData.uan_number || 'Not Available'}</p>
            </div>

            {/* Third Column */}
            <div className="employee-column">
              <table className="summary-table">
                <tbody>
                  <tr style={{ borderLeft: '5px solid green' }}>
                    <td><strong>Paid Days</strong></td>
                    <td>{salaryData.day_worked || 'Not Available'}</td>
                  </tr>
                  <tr style={{ borderLeft: '5px solid red' }}>
                    <td><strong>LOP Days</strong></td>
                    <td> {(() => {
                      const lopDays = getLopDays(salaryData.salary_month, salaryData.salary_year, salaryData.day_worked || 0);
                      return lopDays >= 0 ? lopDays : 'Invalid';
                    })()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="earnings-deductions">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th>Earnings</th>
                <th>Amount</th>
                <th>Deductions</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {(() => {
                const earnings = salaryData.earnings ? JSON.parse(salaryData.earnings) : [];
                const deductions = salaryData.deductions ? JSON.parse(salaryData.deductions) : [];
                const maxRows = Math.max(earnings.length, deductions.length);

                return [...Array(maxRows)].map((_, index) => {
                  const earning = earnings[index];
                  const deduction = deductions[index];

                  // Only render the rows where both earning and deduction are not empty
                  return (
                    <tr key={index}>
                      {earning?.earning_type ? (
                        <>
                          <td>{earning.earning_type.trim()}</td>
                          <td style={{ textAlign: 'right' }}>
                            {earning.earning_amount > 0
                              ? `₹ ${earning.earning_amount.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}`
                              : 'N/A'}
                          </td>
                        </>
                      ) : (
                        <>
                          <td></td>
                          <td></td>
                        </>
                      )}

                      {deduction?.deduction_type ? (
                        <>
                          <td>{deduction.deduction_type.trim()}</td>
                          <td style={{ textAlign: 'right' }}>
                            {deduction.deduction_amount > 0
                              ? `₹ ${deduction.deduction_amount.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}`
                              : 'N/A'}
                          </td>
                        </>
                      ) : (
                        <>
                          <td></td>
                          <td></td>
                        </>
                      )}
                    </tr>
                  );
                });
              })()}
            </tbody>
            <tfoot>
              <tr>
                <th>Gross Earnings</th>
                <th style={{ textAlign: 'right' }}>
                  {salaryData.total_earnings
                    ? parseFloat(salaryData.total_earnings).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    : '0.00'}
                </th>
                <th>Total Deductions</th>
                <th style={{ textAlign: 'right' }}>
                  {salaryData.total_deductions
                    ? parseFloat(salaryData.total_deductions).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    : '0.00'}
                </th>
              </tr>
            </tfoot>
          </table>

        </div>

        <div style={{
          border: '3px dotted #bbb6b6',
          textAlign: 'center',
          margin: '20px 0',
        }}>
          <p style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
            Total Net Payable ₹{salaryData.net_payable.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })} ({salaryData.net_payable_in_words.replace(/\b\w/g, char => char.toUpperCase())} only)
          </p>
          <p style={{ fontSize: '0.8em', fontStyle: 'italic', color: '#555' }}>
            Total Net Payable = (Gross Earnings - Total Deductions)
          </p>
        </div>

        <div className="footersdd" style={{ textAlign: 'center' }}>
          <p>-This is system generated payslip-</p>
        </div>
      </div>
    </div>
  );
};

export default ViewMySalarySlips;
